.Root {
  margin: 1em 0;
}

.Container {
  padding: 8px;
}

.DotPinInput {
  border-radius: 50%;
  padding: 0.4em;
}

.Red {
  background-color: #d0021b;
}

.White {
  background-color: #ffffff;
}

.Orange {
  background-color: #f07126;
}
