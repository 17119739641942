.KeyPad span {
  font-weight: bolder;
  color: #56a84d;
  font-family: Roboto, sans-serif;
}

.KeyPad img {
  padding: 16px 0 16px 0;
  margin: auto;
}

@media (min-height: 500px) {
  .KeyPad span {
    font-size: 28px;
  }
}

@media (min-height: 600px) {
  .KeyPad span {
    font-size: 32px;
  }
}

@media (min-height: 800px) {
  .KeyPad span {
    font-size: 48px;
  }
}

@media (min-height: 1000px) {
  .KeyPad {
    font-size: 56px;
  }
}
