.ConfirmDialog {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1vh;
}

.Img {
  margin: 1vh 0 1vh 0;
}

.ConfirmCaption {
  margin: 1vh 0 1vh 0;
  min-width: 70vw;
}

.ConfirmCaptionDisburseSuccess {
  margin: 1vh 0 1vh 0;
}

.TopImg {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 72px;
  height: auto;
}
.ConfirmButton {
  margin: 1vh 0 1vh 0;
}
