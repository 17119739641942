table {
  font-family: sans-serif;
  border-collapse: collapse;
  width: 100%;
}
th {
  border-bottom: 1px solid #e4e4e4;
  color: #939393;
}
td,
th {
  text-align: left;
  padding: 8px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}
